import { User, Price } from '../services/types';
import { List } from "semantic-ui-react";
import TagList from './TagList';
import PriceEditor from './PriceEditor';
import { useObservable } from 'react-use';
import { currentTagsStore, currentUserStore } from '../services/store';

const PriceList = ({user, priceList}: {user: User, priceList: Price[]}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const tags = useObservable(currentTagsStore.valueChanges);

  return (
    <List relaxed="very" divided size="big" verticalAlign='middle'>
      {priceList.filter(price => price.userId === user.id).map(price => (
        <List.Item key={price.id}>
          <List.Content style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <List.Content>
              {!!price.tagIds.length && tags &&
                <List.Description style={{marginBottom: '4px'}}>
                  <TagList tags={tags.filter(tag => price.tagIds.includes(tag.id))} isRemovable={false} />
                </List.Description>
              }
              <List.Header style={{marginBottom: '2px'}}>¥ {Number(price.price).toLocaleString()}</List.Header>
              {!!price.note && 
                <List.Description>
                  <p style={{lineHeight: '1'}}><small style={{fontSize: '0.9rem'}}>{price.note}</small></p>
                </List.Description>
              }
            </List.Content>
            {(currentUser?.id === price.userId || currentUser?.id === price.groupOwnerId) && (
              <List.Content>
                <PriceEditor price={price as Price} />
              </List.Content>
            )}
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

export default PriceList;
