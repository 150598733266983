import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useObservable } from "react-use";
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { write } from "../services/firebase";
import { currentDateStore, currentGroupStore, currentPriceTemplatesStore, currentTagsStore, currentUserStore } from "../services/store";
import { defaultField, Price } from "../services/types";
import TagSelect from './TagSelect';
import PriceTemplateList from './PriceTemplateList';
import dayjs from 'dayjs';

const PriceInput = () => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const tags = useObservable(currentTagsStore.valueChanges);
  const currentDate = useObservable(currentDateStore.valueChanges);
  const priceTemplates = useObservable(currentPriceTemplatesStore.valueChanges);
  const [priceInput, setPriceInput] = useState<number | ''>('');
  const [tagInput, setTagInput] = useState<string[]>([]);

  const addPrice = (e: React.FormEvent<HTMLFormElement>, price: number | '') => {
    e.preventDefault();
    if (!currentGroup) return;
    if (!price) return;
    if (!currentUser?.id) return;
    if (!currentDate) return;
    const newPrice: Omit<Price, defaultField> = {
      price,
      userId: currentUser.id,
      groupId: currentGroup.id,
      groupOwnerId: currentGroup.ownerId,
      payedAt: Timestamp.fromDate(dayjs().month(currentDate.get('month')).toDate()),
      tagIds: tagInput,
      note: '',
    };
    write(`groups/${currentGroup.id}/prices`, newPrice).then((docRef) => {
      if (!!docRef) {
        setPriceInput('');
      }
    });
  };

  return (
    <Message>
      {tags &&
        <div style={{marginBottom: '8px'}}>
          <TagSelect tags={tags} onChange={(_, {value}) => setTagInput(value as string[])} />
        </div>
      }
      <Form
        style={{marginBottom: '8px'}}
        onSubmit={(e) => addPrice(e, priceInput)}
        disabled={!!priceInput ? priceInput < 1: true}>
        <Input
          fluid
          type="number"
          placeholder="price..."
          value={priceInput}
          onChange={(e) => {
            const v = Number(e.target.value);
            if (!Number.isNaN(v)) {
              setPriceInput(v);
            }
          }}
          action>
          <input />
          <Button
            icon
            type="submit"
            disabled={!!priceInput ? priceInput < 1: true}>
            <Icon name="pencil" />
          </Button>
        </Input>
      </Form>
      {priceTemplates &&
        <PriceTemplateList priceTemplates={priceTemplates} isRemovable={false} />
      }
    </Message>
  );
};

export default PriceInput;
