import { Confirm, Form, Icon, Label } from 'semantic-ui-react';
import { remove, update, write } from '../services/firebase';
import { initialIsOpenedValue } from "./GroupEditor";
import { currentGroupOwnerStore, currentGroupStore, currentMembersStore, currentPriceTemplatesStore, currentTagsStore, currentUserStore } from "../services/store";
import { useObservable } from "react-use";
import { useEffect, useState } from 'react';
import TagList from './TagList';
import PriceTemplateList from './PriceTemplateList';
import { deleteField } from 'firebase/firestore';
import { User } from '../services/types';

const GroupEditForm = ({isOpened, setIsOpened}: {isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<typeof initialIsOpenedValue>>}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const currentOwner = useObservable(currentGroupOwnerStore.valueChanges);
  const currentMembers = useObservable(currentMembersStore.valueChanges);
  const tags = useObservable(currentTagsStore.valueChanges);
  const priceTemplates = useObservable(currentPriceTemplatesStore.valueChanges);

  const [groupName, setGroupName] = useState(currentGroup?.name || '');
  const [deleteFlg, setDeleteFlg] = useState(false);

  const onClose = () => {
    setDeleteFlg(false);
    setIsOpened(initialIsOpenedValue);
  };

  const onMemberDelete = (groupId: string, member: User) => {
    if (deleteFlg) {
      remove(`groups/${groupId}/members`, member.id).then(() => {
        const isDefaultThisGroup = member.defaultGroupId === groupId;
        const groupIds = member.joinedGroupIds?.filter(id => id !== groupId);
        if (groupIds && groupIds.length > 0) {
          // joinedGroupIds内の削除したいgroupId以外は残す。
          update('users', member.id, {defaultGroupId: isDefaultThisGroup ? deleteField() : member.defaultGroupId, joinedGroupIds: groupIds}).then(() => {
            setDeleteFlg(false);
            if (currentUser?.id === member.id) {
              currentGroupStore.update(() => undefined);
            }
          });
        } else {
          // defaultGroupId, joinedGroupIdsともに削除
          update('users', member.id, {defaultGroupId: isDefaultThisGroup ? deleteField() : member.defaultGroupId, joinedGroupIds: deleteField()}).then(() => {
            setDeleteFlg(false);
            if (currentUser?.id === member.id) {
              currentGroupStore.update(() => undefined);
            }
          });
        }
      });
    } else {
      setDeleteFlg(true);
    }
  };

  useEffect(() => {
    if (!currentGroup) return;
    setGroupName(currentGroup.name);
  }, [currentGroup]);

  useEffect(() => {
    if (!currentGroup) return;
    setGroupName(currentGroup.name);
  }, [currentGroup]);

  return (
    <>
      <Confirm
        open={isOpened}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={() => {
          if (!currentGroup) return;
          if (!groupName) return;
          write('groups', {name: groupName}, currentGroup.id).then(() => {
            currentGroupStore.update(prev => {
              if (!prev) return;
              return {...prev, name: groupName};
            });
            onClose();
          });
        }}
        confirmButton='Edit'
        content={(
          <div className="content">
            <Form>
              <Form.Field>
                <label>Group Name</label>
                <input value={groupName} onChange={(e) => {setGroupName(e.target.value)}} disabled={currentGroup?.ownerId !== currentUser?.id} />
              </Form.Field>
              <Form.Field>
                <label>Members</label>
                <Label image>
                  {currentOwner && currentOwner?.pictureUrl && <img src={currentOwner.pictureUrl} alt='' />}
                  {currentOwner && currentOwner?.displayName}
                  <Label.Detail>Owner</Label.Detail>
                </Label>
                {currentGroup && currentMembers && currentMembers.map(member => (
                  <Label key={`member:${member.id}`} image color={deleteFlg ? 'red' : undefined}>
                    {member?.pictureUrl && <img src={member?.pictureUrl} alt='' />}
                    {member.displayName}
                    {currentUser?.id === member.id && <Icon name='delete' onClick={() => onMemberDelete(currentGroup.id, member)} />}
                    {deleteFlg && <Label.Detail>Delete?</Label.Detail>}
                  </Label>
                ))}
              </Form.Field>
              {tags && !!tags.length &&
                <Form.Field>
                  <label>Tags</label>
                  <TagList tags={tags} isRemovable={true} />
                </Form.Field>
              }
              {priceTemplates && !!priceTemplates.filter(priceTemplate => priceTemplate.userId === currentUser?.id).length &&
                <Form.Field>
                  <label>Price Templates</label>
                  <PriceTemplateList priceTemplates={priceTemplates} isRemovable={true} />
                </Form.Field>
              }
            </Form>
          </div>
      )} />
    </>
  );
};

export default GroupEditForm;
