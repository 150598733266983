import { Button } from 'semantic-ui-react';
import { auth } from '../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { login, logout } from '../services/auth';

const LoginButton = () => {

  const [user] = useAuthState(auth);

  const loginOrLogout = () => {
    if (!user?.uid) {
      login();
    } else {
      logout();
    }
  };

  return (
    <Button
      compact
      size='medium'
      content={!user?.uid ? 'Log in with LINE' : 'Log out'}
      positive={!user?.uid}
      onClick={loginOrLogout}
      icon={!user?.uid ? 'sign-in' : 'sign-out'} />
  );
};

export default LoginButton;
