import dayjs from "dayjs";
import { endAt, orderBy, startAt } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useObservable } from "react-use";
import { MenuItemProps, Tab, TabProps } from "semantic-ui-react";
import { getCollectionQuery } from "../services/firebase";
import { currentDateStore, currentGroupOwnerStore, currentGroupStore, currentMembersStore, currentPriceListStore, currentUserStore } from "../services/store";
import { Group, Price } from "../services/types";
import PriceList from "./PriceList";

const MemberTab = () => {

  const owner = useObservable(currentGroupOwnerStore.valueChanges);
  const members = useObservable(currentMembersStore.valueChanges);
  const [panes, setPanes] = useState<{menuItem: MenuItemProps, render: () => JSX.Element}[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentDate = useObservable(currentDateStore.valueChanges);
  const getPriceListQuery = (currentGroup: Group | undefined, currentDate: dayjs.Dayjs | undefined) => {
    if (currentGroup && currentDate) {
      return getCollectionQuery(`groups/${currentGroup.id}/prices`, orderBy('payedAt'), startAt(currentDate.startOf('month').toDate()), endAt(currentDate.endOf('month').toDate()));
    }
    return;
  };
  const [priceDocs] = useCollection(getPriceListQuery(currentGroup, currentDate));
  const handleTabChange = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) => {
    if (typeof data.activeIndex === 'number') {
      setActiveIndex(data.activeIndex);
    }
  };

  useEffect(() => {
    currentPriceListStore.update(() => priceDocs?.docs.reverse().map(doc => doc.data() as Price));
  }, [priceDocs]);

  useEffect(() => {
    setActiveIndex(0);
  }, [currentGroup]);

  const priceList = useObservable(currentPriceListStore.valueChanges);

  useEffect(() => {
    if (owner && priceList) {
      setPanes([
        {
          menuItem: {content: owner.displayName, key: `tabOwner:${owner.id}`},
          render: () => (
            <Tab.Pane key={owner.id}>
              <PriceList user={owner} priceList={priceList} />
            </Tab.Pane>
          ),
        },
      ]);
    }
    if (members?.length && priceList) {
      if (currentUser) {
        const indexedUser = members.map((user, i) => ({user, i: i + 1})).find(indexedUser => indexedUser.user.id === currentUser.id);
        if (indexedUser) {
          setActiveIndex(indexedUser.i);
        }
      }
      setPanes(prev => [
        ...prev,
        ...members.map(member => ({
          menuItem: {content: member.displayName, key: `tabMember:${member.id}`},
          render: () => (
            <Tab.Pane key={`member:${member.id}`}>
              <PriceList user={member} priceList={priceList} />
            </Tab.Pane>
          ),
        }))
      ]);
    }
  }, [owner, members, priceList, currentUser]);

  return (
    <>
      <Tab panes={panes} activeIndex={activeIndex} onTabChange={handleTabChange} />
    </>
    
  );
};

export default MemberTab;
