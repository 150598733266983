import { Container, Menu } from 'semantic-ui-react';
import LoginButton from './LoginButton';
import UserAvator from './UserAvator';
import GroupMenu from './GroupMenu';
import pig from '../assets/pig.svg';

const menuStyle = {
  borderTop: 'none',
  borderRight: 'none',
  borderLeft: 'none',
  borderRadius: 0,
}

const menuRightStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'spaceBetween',
  gap: '4px',
  marginRight: '1rem',
}

const HeaderBar = () => {

  return (
    <header className="headerBar">
      <Menu
        borderless
        style={menuStyle}>
        <Container>
          <Menu.Item>
            <img src={pig} alt="" width="24" height="24" />
          </Menu.Item>
          <GroupMenu />
          <Menu.Menu position='right'>
            <div style={menuRightStyle}>
              <UserAvator />
              <LoginButton />
            </div>
          </Menu.Menu>
        </Container>
      </Menu>
    </header>
  );
};

export default HeaderBar;
