import liff from '@line/liff';
import { useState } from "react";
import { useObservable } from "react-use";
import { Dropdown, Icon, Label } from "semantic-ui-react";
import { write } from "../services/firebase";
import { currentGroupOwnerStore, currentGroupStore, currentMembersStore, currentUserStore, snackbarStore } from "../services/store";
import GroupEditForm from "./GroupEditForm";
import PriceTemplateForm from "./PriceTemplateForm";
import TagForm from "./TagForm";
import MonthlyReport from './MonthlyReport';

export const initialIsOpenedValue = {
  tag: false,
  price: false,
  group: false,
  report: false,
};

const GroupEditor = () => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const currentOwner = useObservable(currentGroupOwnerStore.valueChanges);
  const members = useObservable(currentMembersStore.valueChanges);

  const [isOpened, setIsOpened] = useState(initialIsOpenedValue);

  const setDefaultGroup = () => {
    if (!currentUser || !currentGroup) return;
    write('users', {defaultGroupId: currentGroup.id}, currentUser.id).then(() => {
      snackbarStore.update(prev => ({
        ...prev,
        isOpen: true,
        children: <Label size="large" color="green">Default group set successfully.</Label>,
      }));
    });
  };

  const inviteMember = () => {
    if (!currentOwner) return;
    if (!currentGroup) return;

    if (!liff.isLoggedIn()) {
      liff.ready.then(() => {
        liff.login();
      });
    }

    if (liff.isLoggedIn() && liff.isApiAvailable('shareTargetPicker')) {
      const inviteUrl = window.location.host + window.location.pathname + '?groupId=' + currentGroup.id;
      liff.shareTargetPicker([
          {
            type: 'text',
            text: `${currentOwner.displayName}さんから、『わりかん家計簿 - Shared Expenses』に招待されました。\n\n下記URLにアクセスし、ログイン後に招待されたグループ(${currentGroup.name})へ参加ができます。\nhttps://${inviteUrl}`,
          },
        ]).then((res) => {
        if (res) {
          snackbarStore.update(prev => ({
            ...prev,
            isOpen: true,
            children: <Label size="large" color="green">{`[${res.status}] Message sent!`}</Label>,
          }));
        }
      }).catch(() => {
        snackbarStore.update(prev => ({
          ...prev,
          isOpen: true,
          children: <Label size="large" color="orange">{'[Eroor] Something wrong happen'}</Label>,
        }));
      });
    }
  };

  return (
    <div className="mb3">
      <Label ribbon>{currentGroup?.name}</Label>
      <Dropdown
        className="button right floated mini icon"
        icon="ellipsis horizontal"
        direction="left"
        trigger={<></>}>
        <Dropdown.Menu>
          <Dropdown.Item icon="sticky note" text="Monthly Report" onClick={() => setIsOpened({...initialIsOpenedValue, report: true})} />
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => setIsOpened({...initialIsOpenedValue, tag: true})}>
            <Icon.Group className="icon">
              <Icon name='tag' />
              <Icon corner='bottom right' name='plus' />
            </Icon.Group>
            <span className="text">Add New Tag</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setIsOpened({...initialIsOpenedValue, price: true})}>
            <Icon.Group className="icon">
              <Icon name='repeat' />
              <Icon corner='bottom right' name='yen' />
            </Icon.Group>
            <span className="text">Add New Price Template</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item icon="edit" text="Edit Group" onClick={() => setIsOpened({...initialIsOpenedValue, group: true})} />
          {currentUser?.defaultGroupId !== currentGroup?.id && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item icon="check circle" text="Set Default Group" onClick={setDefaultGroup} />
            </>
          )}
          {(!members || (members && members.length < 1)) && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item icon="commenting" text={`Invite the Member`} onClick={inviteMember} />
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {currentGroup && (
        <>
          <TagForm isOpened={isOpened.tag} setIsOpened={setIsOpened} />
          <PriceTemplateForm isOpened={isOpened.price} setIsOpened={setIsOpened} />
          <GroupEditForm isOpened={isOpened.group} setIsOpened={setIsOpened} />
          <MonthlyReport isOpened={isOpened.report} setIsOpened={setIsOpened} />
        </>
      )}
    </div>
  );
};

export default GroupEditor;
