import { Button, Divider, Grid, Statistic } from "semantic-ui-react";
import { useObservable } from "react-use";
import { currentDateStore } from "../services/store";

const MonthSelect = () => {

  const currentDate = useObservable(currentDateStore.valueChanges);

  const prev = () => {
    currentDateStore.update(value => value.subtract(1, 'month'));
  };
  const next = () => {
    currentDateStore.update(value => value.add(1, 'month'));
  };

  return (
    <div className="mt3 mb3">
      <Grid verticalAlign="middle" columns={3}>
        <Grid.Row>
          <Grid.Column textAlign="right" width={4}>
            <Button icon="caret left" size="large" onClick={prev} />
          </Grid.Column>
          <Grid.Column textAlign="center" width={8}>
            <Divider fitted horizontal>
              <Statistic size="small">
                <Statistic.Label>{currentDate && currentDate.year()}</Statistic.Label>
                <Statistic.Value>{currentDate && currentDate.month() + 1}</Statistic.Value>
              </Statistic>
            </Divider>
          </Grid.Column>
          <Grid.Column textAlign="left" width={4}>
            <Button icon="caret right" size="large" onClick={next} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default MonthSelect;
