import { useEffect, useState } from 'react';
import { authStateChangedUnsubscribe } from './services/auth';
import HeaderBar from './components/HeaderBar';
import './App.css';
import { Container, Segment } from 'semantic-ui-react';
import MemberTab from './components/MemberTab';
import { useObservable } from 'react-use';
import { currentGroupStore, currentUserStore } from './services/store';
import MonthSelect from './components/MonthSelect';
import PriceInput from './components/PriceInput';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getDocRef } from './services/firebase';
import GroupEditor from './components/GroupEditor';
import InitGroupStores from './components/InitGroupStores';
import Snackbar from './components/Snackbar';
import { getGroupIdFromParams } from './services/util';
import MemberInvitation from './components/MemberInvitation';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { User } from './services/types';
import pig from './assets/pig.svg';

const App = () => {
  const [user] = useAuthState(auth);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const [invitedGroupId, setInvitedGroupId] = useState<string>();
  const [userDocData] = useDocumentData(getDocRef('users', user?.uid));

  useEffect(() => {
    currentUserStore.update(() => userDocData as User);
    return () => {
      currentUserStore.reset();
      authStateChangedUnsubscribe();
    };
  }, [user, userDocData]);

  useEffect(() => {
    setInvitedGroupId(getGroupIdFromParams());
  }, []);

  return (
    <div className='App pb4'>
      <HeaderBar />
      <main className='pt2 pb4'>
        <Container text>
          {user?.uid && currentGroup && (
            <div style={{ marginBottom: '7rem' }}>
              <Segment>
                <InitGroupStores />
                <GroupEditor />
                <MonthSelect />
                <PriceInput />
                <MemberTab />
              </Segment>
            </div>
          )}
          <section className='center'>
            <p style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <img src={pig} alt='' width='68' height='68' />
            </p>
            <h1
              style={{
                marginTop: '0',
                fontSize: '1.4rem',
                fontWeight: 'normal',
                fontFamily: '"Zen Kurenaido", sans-serif',
              }}
            >
              わりかん家計簿
              <br />
              <small>Shared Expenses</small>
            </h1>
            <div
              style={{
                fontSize: '1rem',
                fontFamily: '"Zen Kurenaido", sans-serif',
              }}
            >
              <p>
                developed by{' '}
                <a
                  href='https://twitter.com/michiochips'
                  target='_blank'
                  rel='noreferrer'
                >
                  @michiochips
                </a>
              </p>
              {!user?.uid && (
                <p
                  style={{
                    marginTop: '3.5rem',
                    fontSize: '0.9rem',
                    opacity: '0.9',
                  }}
                >
                  現在、テスト運用中のためログインするには事前登録が必要になります。
                  <br />
                  詳細は、Twitter(
                  <a
                    href='https://twitter.com/michiochips'
                    target='_blank'
                    rel='noreferrer'
                  >
                    @michiochips
                  </a>
                  )のDMにてお問い合わせください。
                </p>
              )}
            </div>
          </section>
          {!user?.uid && <MemberInvitation groupId={invitedGroupId} />}
        </Container>
      </main>
      <Snackbar />
    </div>
  );
};

export default App;
