import { where } from "firebase/firestore";
import { SemanticCOLORS } from "semantic-ui-react";
import { getCollectionQuery } from "./firebase";

export const Colors: SemanticCOLORS[] = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];

export const getMembersQuery = (memberIds: string[] | undefined) => {
  if (memberIds && memberIds.length > 0) {
    return getCollectionQuery('users', where('id', 'in', memberIds));
  }
  return;
};

export const getParams = (queryPrams: string = window.location.search): {key: string, value: string}[] | undefined => {
  const str = queryPrams.substring(1);
  if (!str) return;
  return str.split('&').map(param => {
    const keyValue = param.split('=');
    return {key: keyValue[0], value: keyValue[1]};
  });
};

export const getGroupIdFromParams = (queryPrams: string = window.location.search): string | undefined => {
  const params = getParams(queryPrams);
  if (!params) return;
  const groupId = params.find(param => param.key === 'groupId');
  if (!groupId) return;
  return groupId.value;
};

export const getGroupNameFromParams = (queryPrams: string = window.location.search): string | undefined => {
  const params = getParams(queryPrams);
  if (!params) return;
  const groupId = params.find(param => param.key === 'groupName');
  if (!groupId) return;
  return groupId.value;
};
