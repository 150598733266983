import { Dropdown, DropdownProps } from "semantic-ui-react";
import { Tag } from "../services/types";

type TagSelectProps = {
  tags: Tag[];
  defaultValue?: string[];
  onChange: ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void) | undefined;
};

const TagSelect = ({tags, defaultValue, onChange}: TagSelectProps) => {
  return (
    <Dropdown
      fluid
      clearable
      multiple
      selection
      defaultValue={defaultValue}
      placeholder='Tags...'
      onChange={onChange}
      options={tags.map(tag => ({key: tag.id, value: tag.id, label: {
        color: tag.color,
        icon: tag.icon,
        content: tag.label,
        key: tag.id,
      }}))}
      renderLabel={(props) => props.label}
    />
  );
};

export default TagSelect;
