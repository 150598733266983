import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { useObservable } from "react-use";
import { Icon, Label } from "semantic-ui-react";
import { remove, write } from "../services/firebase";
import { currentDateStore, currentUserStore } from "../services/store";
import { defaultField, Price, PriceTemplate } from "../services/types";

const PriceTemplateList = ({priceTemplates, isRemovable}: {priceTemplates: PriceTemplate[], isRemovable: boolean}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentDate = useObservable(currentDateStore.valueChanges);

  const onPriceTempateDelete = (groupId: string, priceTemplateId: string) => {
    remove(`groups/${groupId}/priceTemplates`, priceTemplateId);
  };

  const addPrice = (e: React.MouseEvent<HTMLElement, MouseEvent>, priceTemplate: PriceTemplate) => {
    e.preventDefault();
    if (isRemovable) return;
    if (!currentDate) return;
    const newPrice: Omit<Price, defaultField> = {
      price: priceTemplate.price,
      userId: priceTemplate.userId,
      groupId: priceTemplate.groupId,
      groupOwnerId: priceTemplate.groupOwnerId,
      payedAt: Timestamp.fromDate(dayjs().month(currentDate.get('month')).toDate()),
      tagIds: priceTemplate.tagIds,
      note: priceTemplate.note,
    };
    write(`groups/${priceTemplate.groupId}/prices`, newPrice);
  };

  const addPrices = (e: React.MouseEvent<HTMLElement, MouseEvent>, priceTemplates: PriceTemplate[]) => {
    priceTemplates = priceTemplates.filter(priceTemplate => priceTemplate.userId === currentUser?.id);
    priceTemplates.forEach(priceTemplate => addPrice(e, priceTemplate));
  };

  return (
    <>
      {!isRemovable && priceTemplates.filter(priceTemplate => priceTemplate.userId === currentUser?.id).length > 0 && (
        <Label as='a' size='mini' color="grey" style={{marginBottom: '.28571428em'}} onClick={(e) => addPrices(e, priceTemplates)}>
          <Icon name='add' />
          Add all
        </Label>
      )}
      {priceTemplates.filter(priceTemplate => priceTemplate.userId === currentUser?.id).map(priceTemplate => {
        if (isRemovable) {
          return (
            <Label key={priceTemplate.id} style={{marginBottom: '.28571428em'}}>
              <Icon name='yen' />
              {priceTemplate.title}
              <Icon name='delete' onClick={() => onPriceTempateDelete(priceTemplate.groupId, priceTemplate.id)} />
            </Label>);
        } else {
          return (
            <Label as='a' key={priceTemplate.id} size='mini' style={{marginBottom: '.28571428em'}} onClick={(e) => addPrice(e, priceTemplate)}>
              <Icon name='yen' />
              {priceTemplate.title}
            </Label>);
        }
      })}
    </>
  );
};

export default PriceTemplateList;
