import { useObservable, useTimeoutFn } from "react-use";
import { tap } from "rxjs/operators";
import { TransitionablePortal, Segment,  } from "semantic-ui-react";
import { snackbarStore, snackbarStoreInitialValue } from "../services/store";
import { SnackbarProps } from "../services/types";

const Snackbar = () => {

  const {isOpen, duration, children}: SnackbarProps = useObservable(snackbarStore.valueChanges.pipe(
    tap(({isOpen}) => {
      if (isOpen) {
        reset();
      }
    })
  ), snackbarStoreInitialValue);

  const [,, reset] = useTimeoutFn(() => {
    if (isOpen) {
      snackbarStore.update(value => ({...value, isOpen: false}));
    }
  }, duration);

  return (
    <TransitionablePortal open={isOpen} transition={{animation: 'slide up'}}>
      <div style={{
        left: '50%',
        position: 'fixed',
        bottom: '20px',
        zIndex: 1000,
      }}>
        <div style={{transform: 'translateX(-50%)'}}>
          <Segment raised style={{padding: 0}}>
            {children}
          </Segment>
        </div>
      </div>
    </TransitionablePortal>
  );
};

export default Snackbar;
