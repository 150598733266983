import { useState } from "react";
import { useObservable } from "react-use";
import { Button, Confirm, Form, Label, Segment } from "semantic-ui-react";
import { write } from "../services/firebase";
import { currentGroupStore, currentUserStore } from "../services/store";
import { defaultField, Tag } from "../services/types";
import { Colors } from "../services/util";
import { initialIsOpenedValue } from "./GroupEditor";

const TagForm = ({isOpened, setIsOpened}: {isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<typeof initialIsOpenedValue>>}) => {

  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const currentUser = useObservable(currentUserStore.valueChanges);
  const initialTagValue: Omit<Tag, defaultField> = {
    label: 'New Tag',
    userId: currentUser?.id || '',
    groupId: currentGroup?.id || '',
    groupOwnerId: currentGroup?.ownerId || '',
  };
  const [tagState, setTagState] = useState(initialTagValue);
  const onClose = () => {
    setTagState(initialTagValue);
    setIsOpened(initialIsOpenedValue);
  };

  return (
    <>
      <Confirm
        open={isOpened}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={() => {
          if (!currentGroup) return;
          if (!currentUser) return;
          write(`groups/${currentGroup.id}/tags`, {...tagState, groupId: currentGroup.id, groupOwnerId: currentGroup.ownerId, userId: currentUser.id}).then(() => {
            onClose();
          });
        }}
        confirmButton='Add'
        content={(
          <div className="content">
            <Segment placeholder style={{minHeight: 'auto'}}>
              <div className="center">
                <Label icon={tagState?.icon ? tagState?.icon : 'hashtag'} color={tagState?.color} content={tagState.label} />
              </div>
            </Segment>
            <Form>
              <Form.Field>
                <label>Label</label>
                <input placeholder='Tag name...' value={tagState.label} onChange={(e) => setTagState(prev => ({...prev, label: e.target.value}))} />
              </Form.Field>
              <Form.Field>
                <label>Icon</label>
                <input placeholder='Tag icon name...' value={tagState.icon} onChange={(e) => setTagState(prev => ({...prev, icon: e.target.value as any}))} />
              </Form.Field>
              <Form.Field>
                <label>Color</label>
                {Colors.map(color => (
                  <Button key={color} icon color={color} onClick={() => setTagState(prev => ({...prev, color: color}))} />
                ))}
                <Button icon onClick={() => {
                  setTagState(prev => {
                    delete prev.color;
                    return {...prev};
                  });
                }} />
              </Form.Field>
            </Form>
          </div>
      )} />
    </>
  );
};

export default TagForm;
