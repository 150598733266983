import { useLocalStorage } from "react-use";
import { useEffect, useState } from "react";
import LoginButton from './LoginButton';
import { Container, Label, Icon } from "semantic-ui-react";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { getCollectionQuery, getDocRef } from "../services/firebase";
import { limit } from "firebase/firestore";

const InvitedGroupInfo = ({groupId}: {groupId: string}) => {

  const [group] = useDocumentData(getDocRef('groups', groupId));
  const [members] = useCollectionData(getCollectionQuery(`groups/${groupId}/members`, limit(1)));
  const [,setLsGroupId, removeLsGroupId] = useLocalStorage<string>('invitedGroupId');
  const [,setLsGroupOwnerId, removeLsGroupOwnerId] = useLocalStorage<string>('invitedGroupOwnerId');
  const [isNoGroup, setIsNoGroup] = useState<boolean>(false);

  useEffect(() => {
    // 有効なgroupIdであれば、localStorageにgroupIdを保存
    if (!!group?.id && (!members || (members && members.length < 1))) {
      setLsGroupId(group.id);
      setLsGroupOwnerId(group.ownerId);
    } else {
      if (!!group?.id) {
        setIsNoGroup(true);
      }
      removeLsGroupId();
      removeLsGroupOwnerId();
    }
  }, [group, members, removeLsGroupId, removeLsGroupOwnerId, setLsGroupId, setLsGroupOwnerId]);

  return (
    <div className="center mt4 mb4">
      {isNoGroup ? (
        <div className="mb3">
          {'You can not join '}
          <Label size="large">
            <Icon name='home' />
            {group?.name ? group.name : 'No Group...'}
          </Label>
          {' that is already full menbers.'}
        </div>
      ) : (
        <>
          <div className="mb3">
            {group && 'To join '}
            <Label size="large">
              {group && <Icon name='home' />}
              {group?.name ? group.name : 'Sorry, there is No Group here...'}
            </Label>
            {group && ' group, please login with below button.'}
          </div>
          {group && <LoginButton />}
        </>
      )}
    </div>
  );
};

const MemberInvitation = ({groupId}: {groupId: string | undefined}) => {
  return groupId !== undefined ?
    <Container text>
      {groupId && <InvitedGroupInfo groupId={groupId} />}
    </Container> :
    <></>
  ;
};

export default MemberInvitation;
