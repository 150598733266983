import { useObservable } from 'react-use';
import { Icon, Image } from 'semantic-ui-react'
import { currentUserStore } from '../services/store';

const UserAvator = () => {

  const currentUser = useObservable(currentUserStore.valueChanges);

  return (
    <>
      {!!currentUser ?
        <Image avatar src={currentUser.pictureUrl} /> :
        <Icon circular name='user' />
      }
    </>
  );
};

export default UserAvator;
