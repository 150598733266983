import { useState } from 'react';
import { Confirm, Form } from 'semantic-ui-react';
import { write } from "../services/firebase";
import { defaultField, PriceTemplate } from "../services/types";
import { initialIsOpenedValue } from "./GroupEditor";
import { currentGroupStore, currentTagsStore, currentUserStore } from "../services/store";
import { useObservable } from "react-use";
import TagSelect from "./TagSelect";

const PriceTemplateForm = ({isOpened, setIsOpened}: {isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<typeof initialIsOpenedValue>>}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const tags = useObservable(currentTagsStore.valueChanges);

  const initialPriceTemplateValue: Omit<PriceTemplate, defaultField> = {
    price: 0,
    userId: currentUser?.id || '',
    groupId: currentGroup?.id || '',
    groupOwnerId: currentGroup?.ownerId || '',
    note: '',
    tagIds: [],
    payedAt: null,
    title: 'New Price Template'
  };

  const [priceTemplateState, setPriceTemplateState] = useState(initialPriceTemplateValue);
  const onClose = () => {
    setPriceTemplateState(initialPriceTemplateValue);
    setIsOpened(initialIsOpenedValue);
  };

  return (
    <>
      <Confirm
        open={isOpened}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={() => {
          if (!currentGroup) return;
          if (!currentUser) return;
          write(`groups/${currentGroup.id}/priceTemplates`, {...priceTemplateState, userId: currentUser.id, groupId: currentGroup.id, groupOwnerId: currentGroup.ownerId}).then(() => {
            onClose();
          });
        }}
        confirmButton='Add'
        content={(
          <div className="content">
            <Form>
              <Form.Field>
                <label>Title</label>
                <input value={priceTemplateState.title} onChange={(e) => setPriceTemplateState(prev => ({...prev, title: e.target.value}))} />
              </Form.Field>
              <Form.Field>
                <label>Price</label>
                <input type="number" placeholder='Price...' value={priceTemplateState.price} onChange={(e) => setPriceTemplateState(prev => ({...prev, price: Number(e.target.value)}))} />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <input type="text" placeholder='Note...' value={priceTemplateState.note} onChange={(e) => setPriceTemplateState(prev => ({...prev, note: e.target.value}))} />
              </Form.Field>
              <Form.Field>
                <label>Tags</label>
                {!!tags && (
                  <TagSelect
                    tags={tags}
                    onChange={(_, {value}) => setPriceTemplateState(prev => {
                      if (typeof value === 'object') {
                        return {...prev, tagIds: value as string[]};
                      }
                      return prev;
                    })} />
                )}
              </Form.Field>
            </Form>
          </div>
      )} />
    </>
  );
};

export default PriceTemplateForm;
