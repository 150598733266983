import { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { remove } from "../services/firebase";
import { Price } from "../services/types";
import PriceForm from './PriceForm';

const PriceEditor = ({price}: {price: Price}) => {

  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Dropdown
        className="button right floated mini icon"
        icon="ellipsis horizontal"
        direction="left"
        trigger={<></>}>
        <Dropdown.Menu>
          <Dropdown.Item icon="edit" text="Edit" onClick={() => setIsOpened(true)} />
          <Dropdown.Divider />
          <Dropdown.Item icon="delete" text="Delete" onClick={() => remove(`groups/${price.groupId}/prices`, price.id)} />
        </Dropdown.Menu>
      </Dropdown>
      <PriceForm isOpened={isOpened} setIsOpened={setIsOpened} price={price} />
    </>
  );
};

export default PriceEditor;
