import { useObservable } from "react-use";
import { Icon, Label } from "semantic-ui-react";
import { remove } from "../services/firebase";
import { currentUserStore } from "../services/store";
import { Tag } from "../services/types";

const TagList = ({tags, isRemovable}: {tags: Tag[], isRemovable: boolean}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);

  const onTagDelete = (groupId: string, tagId: string) => {
    remove(`groups/${groupId}/tags`, tagId);
  };

  return (
    <>
      {tags.map(tag => (
        <Label key={tag.id} color={tag?.color} size={!isRemovable ? 'mini' : undefined} style={{marginBottom: '.28571428em'}}>
          <Icon name={tag?.icon ? tag.icon : 'hashtag'} />
          {tag.label}
          {isRemovable && (tag.userId === currentUser?.id || tag.groupOwnerId === currentUser?.id) && <Icon name='delete' onClick={() => onTagDelete(tag.groupId, tag.id)} />}
        </Label>
      ))}
    </>
  );
};

export default TagList;