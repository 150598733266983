import { DocumentData, limit, orderBy } from "firebase/firestore";
import { useEffect } from "react";
import { useDocumentData, useCollection, useCollectionData } from "react-firebase-hooks/firestore";
import { useObservable } from "react-use";
import { getDocRef, getCollectionQuery } from "../services/firebase";
import { currentMembersStore, currentTagsStore, currentPriceTemplatesStore, currentGroupOwnerStore, currentGroupStore } from "../services/store";
import { PriceTemplate, Tag, User as AppUser } from "../services/types";
import { getMembersQuery } from "../services/util";

const InitMembersStore = ({memberDocsData}: {memberDocsData: DocumentData[] | undefined}) => {
  const [members] = useCollection(getMembersQuery(memberDocsData && memberDocsData.map(member => member.id)));
  useEffect(() => {
    if (!members) return;
    currentMembersStore.update(() => (members?.docs.map(doc => doc.data()) as AppUser[]));
    return () => currentMembersStore.reset();
  }, [members]);
  return <></>;
};

const InitGroupStores = () => {
  const currentGroup = useObservable(currentGroupStore.valueChanges);

  const [groupOwnerDocData] = useDocumentData(getDocRef('users', currentGroup?.ownerId));
  const [tagDocs] = useCollection(getCollectionQuery(currentGroup && `groups/${currentGroup.id}/tags`, orderBy('timestamp')));
  const [priceTemplateDocs] = useCollection(getCollectionQuery(currentGroup && `groups/${currentGroup.id}/priceTemplates`, orderBy('timestamp')));
  const [memberDocsData] = useCollectionData(getCollectionQuery(currentGroup && `groups/${currentGroup.id}/members`, limit(1)));

  useEffect(() => {
    currentGroupOwnerStore.update(() => (groupOwnerDocData as AppUser));
    currentTagsStore.update(() => (tagDocs?.docs.map(doc => doc.data()) as Tag[]));
    currentPriceTemplatesStore.update(() => (priceTemplateDocs?.docs.map(doc => doc.data()) as PriceTemplate[]));
    return () => {
      currentGroupOwnerStore.reset();
      currentTagsStore.reset();
      currentPriceTemplatesStore.reset();
    };
  }, [currentGroup, groupOwnerDocData, priceTemplateDocs, tagDocs]);

  return (
    <>
      <InitMembersStore memberDocsData={memberDocsData} />
    </>
  );
};

export default InitGroupStores;
