import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator, collection, setDoc, doc, updateDoc, deleteDoc, query, QueryConstraint, Timestamp } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(config);

export const functions = getFunctions(app, 'asia-northeast1');
export const db = getFirestore(app);
export const auth = getAuth(app);

if (process.env.REACT_APP_NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
}

// ------------------------------------------------------------

/**
 * コレクションクエリを取得する。
 *
 * @param collectionPath コレクションパス
 * @param queryConstraints: QueryConstraint[]
 * @returns クエリ
 */
export const getCollectionQuery = (collectionPath: string | undefined, ...queryConstraints: QueryConstraint[]) => {
  if (!collectionPath) return;
  return queryConstraints.length ?
    query(collection(db, collectionPath), ...queryConstraints) :
    query(collection(db, collectionPath));
}

/**
 * ドキュメントリファレンスを取得する。
 *
 * @param collectionPath コレクションパス
 * @param docId ドキュメントID | undefined
 * @returns ドキュメントリファレンス | undefined
 */
export const getDocRef = (collectionPath: string, docId: string | undefined) => {
  if (!docId) return;
  return doc(db, collectionPath, docId);
}

/**
 * 自動or手動でIDを振ってコレクションにドキュメントを追加する。
 *
 * @param collectionPath コレクションパス
 * @param docData ドキュメントデータ
 * @param id ドキュメントID
 * @returns Promise型のドキュメントのリファレンス / idを指定した場合はPromise型のvoidが返る
 */
export const write = async <T>(collectionPath: string, docData: T, id?: string) => {
  if (!!id) {
    return setDoc(doc(db, collectionPath, id), {
      ...docData,
      id,
      timestamp: Timestamp.now(),
    }, { merge: true });
  } else {
    try {
      const docRef = doc(collection(db, collectionPath));
      await setDoc(docRef, {
        ...docData,
        id: docRef.id,
        timestamp: Timestamp.now(),
      });
      return docRef;
    } catch (error) {
      console.error(error);
    }
  }
};

/**
 * ドキュメントの一部のフィールドを更新する。
 *
 * @param collectionPath コレクションパス
 * @param id ドキュメントID
 * @param docData ドキュメントデータ
 * @returns Promise<void>
 */
export const update = <T extends { [x: string]: any; }>(collectionPath: string, id: string, docData: T) => {
  return updateDoc(doc(db, collectionPath, id), docData);
};

/**
 * ドキュメントを削除する。
 *
 * @param collectionPath コレクションパス
 * @param id ドキュメントID
 * @returns Promise<void>
 */
export const remove = (collectionPath: string, id: string) => {
  return deleteDoc(doc(db, collectionPath, id));
};
