import { useState } from "react";
import { Confirm, Form } from "semantic-ui-react";
import { write } from "../services/firebase";
import { Price } from '../services/types';
import { currentTagsStore } from "../services/store";
import { useObservable } from "react-use";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import TagSelect from "./TagSelect";

const PriceForm = ({isOpened, setIsOpened, price}: {isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<boolean>>, price: Price}) => {

  const tags = useObservable(currentTagsStore.valueChanges);

  const [priceState, setPriceState] = useState(price);
  const onClose = () => {
    setIsOpened(false);
  };

  return (
    <>
      <Confirm
        open={isOpened}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={() => {
          write(`groups/${price.groupId}/prices`, priceState, price.id).then(() => {
            onClose();
          });
        }}
        confirmButton='Edit'
        content={(
          <div className="content">
            <Form>
              <Form.Field>
                <label>Price</label>
                <input type="number" placeholder='Price...' value={priceState.price} onChange={(e) => setPriceState(prev => ({...prev, price: Number(e.target.value)}))} />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <input type="text" placeholder='Note...' value={priceState.note} onChange={(e) => setPriceState(prev => ({...prev, note: e.target.value}))} />
              </Form.Field>
              <Form.Field>
                <label>Tags</label>
                {!!tags && (
                  <TagSelect
                    tags={tags}
                    defaultValue={priceState.tagIds}
                    onChange={(_, { value }) => setPriceState(prev => {
                      if (typeof value === 'object') {
                        return { ...prev, tagIds: value as string[] };
                      }
                      return prev;
                    })} />
                )}
              </Form.Field>
              {priceState.payedAt && (
                <Form.Field>
                  <label>PayedAt</label>
                  <input
                    type="date"
                    value={dayjs(priceState.payedAt.toDate()).format('YYYY-MM-DD')}
                    min={dayjs(priceState.payedAt.toDate()).startOf('month').format('YYYY-MM-DD')}
                    max={dayjs(priceState.payedAt.toDate()).endOf('month').format('YYYY-MM-DD')}
                    onChange={(e) => setPriceState(prev => ({...prev, payedAt: Timestamp.fromDate(dayjs(e.target.value).toDate())}))} />
                </Form.Field>
              )}
            </Form>
          </div>
      )} />
    </>
  );
};

export default PriceForm;
