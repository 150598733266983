import { limit, where, Timestamp } from 'firebase/firestore';
import { useEffect } from "react";
import { useCollection, useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { useLocalStorage, useObservable } from "react-use";
import { Dropdown } from "semantic-ui-react";
import { getCollectionQuery, getDocRef, write } from "../services/firebase";
import { currentGroupStore, currentUserStore } from "../services/store";
import { defaultField, Group, Member } from '../services/types';

const groupLimitNum = 1;

const GroupMenu = () => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentGroup = useObservable(currentGroupStore.valueChanges);
  const [lsGroupId,, remove] = useLocalStorage<string>('invitedGroupId');
  const [lsGroupOwnerId,, removeLsGroupOwnerId] = useLocalStorage<string>('invitedGroupOwnerId');

  const [myGroups] = useCollection(getCollectionQuery(currentUser && 'groups', where('ownerId', '==', currentUser?.id)));
  const [joinedGroups] = useCollectionData(getCollectionQuery(currentUser?.joinedGroupIds && 'groups', where('id', 'in', currentUser?.joinedGroupIds), limit(groupLimitNum)));

  const [defaultGroupDoc, defaultGroupLoading] = useDocument(getDocRef('groups', currentUser?.defaultGroupId));
  
  const create = () => {
    if (!currentUser) return;
    const newGroup: Omit<Group, defaultField> = {
      name: `New Group (${(!!myGroups ? myGroups.docs.length + 1 : 1)})`,
      ownerId: currentUser.id,
    };
    // グループを新規作成
    write('groups', newGroup).then((docRef) => {
      if (!!docRef) {
        // 新規作成グループを選択中グループに更新
        currentGroupStore.update(() => ({
          ...newGroup,
          id: docRef.id,
          timestamp: Timestamp.now(),
        }));
        // 既存設定がなければ新規作成グループをデフォルトグループに設定
        if (!currentUser?.defaultGroupId) {
          write('users', { defaultGroupId: docRef.id }, currentUser.id);
        }
      }
    });
  };

  useEffect(() => {
    // デフォルトグループを現在のグループに設定
    if (!currentGroup && !!defaultGroupDoc && !defaultGroupLoading) {
      currentGroupStore.update(() => ({
        ...defaultGroupDoc.data(),
        id: defaultGroupDoc.id
      } as Group));
    }
  }, [defaultGroupDoc, defaultGroupLoading, currentGroup]);

  useEffect(() => {
    // ログイン完了後に招待されたグループに参加
    if (!currentUser) return;
    if (lsGroupId && lsGroupOwnerId) {
      const newMember: Omit<Member, 'timestamp'> = {
        id: currentUser.id,
        groupId: lsGroupId,
        groupOwnerId: lsGroupOwnerId,
      };
      write(`groups/${lsGroupId}/members`, newMember, newMember.id).then(() => {
        write('users', { defaultGroupId: currentUser.defaultGroupId || lsGroupId, joinedGroupIds: [lsGroupId] }, currentUser.id).then(() => {
          remove();
          removeLsGroupOwnerId();
        });
      });
    }
  }, [currentUser, lsGroupId, lsGroupOwnerId, remove, removeLsGroupOwnerId]);

  return (
    <>
      {currentUser && (
        <Dropdown
          style={{maxWidth: '130px'}}
          text={(currentGroup && currentGroup.name) || 'Select Group'}
          pointing
          className='link item'>
          <Dropdown.Menu>
            {myGroups && (
              <>
                {myGroups.docs.length < groupLimitNum && <Dropdown.Item icon='plus' text='Create new group' onClick={create} />}
                {!!myGroups.docs.length && myGroups.docs.length < groupLimitNum && <Dropdown.Divider />}
                {myGroups.docs.map((doc) => {
                  const group = doc.data() as Group;
                  return (
                    <Dropdown.Item key={doc.id} text={group.name} onClick={() => currentGroupStore.update(() => ({...group, id: doc.id}))} />
                  );
                })}
              </>
            )}
            {joinedGroups && (
              <>
                {!!joinedGroups.length && (myGroups && !!myGroups.docs.length) && <Dropdown.Divider />}
                {joinedGroups.map((doc) => {
                  const group = doc as Group;
                  return (
                    <Dropdown.Item key={`joined:${doc.id}`} text={group.name} onClick={() => currentGroupStore.update(() => ({...group, id: doc.id}))} />
                  );
                })}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default GroupMenu;
