import { Store } from '@lacolaco/reactive-store';
import dayjs from 'dayjs';
import { SnackbarProps, Group, Tag, PriceTemplate, User, Price } from './types';

export const snackbarStoreInitialValue: SnackbarProps = {
  isOpen: false,
  duration: 3000,
  children: '',
};

export const snackbarStore = new Store<SnackbarProps>({initialValue: snackbarStoreInitialValue});

export const currentGroupStore = new Store<Group | undefined>({initialValue: undefined});
export const currentTagsStore = new Store<Tag[] | undefined>({initialValue: undefined});
export const currentPriceTemplatesStore = new Store<PriceTemplate[] | undefined>({initialValue: undefined});
export const currentUserStore = new Store<User | undefined>({initialValue: undefined});
export const currentGroupOwnerStore = new Store<User | undefined>({initialValue: undefined});
export const currentMembersStore = new Store<User[] | undefined>({initialValue: undefined});
export const currentPriceListStore = new Store<Price[] | undefined>({initialValue: undefined});

export const currentDateStore = new Store<dayjs.Dayjs>({initialValue: dayjs()});
