import liff from '@line/liff';
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from './firebase';
import { LoginToken } from './types';

export const login = () => {
  liff.ready.then(() => {
    liff.login();
  });
};

export const logout = () => {
  liff.ready.then(() => {
    signOut(auth).then(() => {
      liff.logout();
      window.location.reload();
    });
  });
};

export const authStateChangedUnsubscribe = onAuthStateChanged(auth, async user => {
  // firebase auth not logged in & line logged in
  if (!user && liff.isLoggedIn()) {
    // line access token
    const accessToken = liff.getAccessToken();
    // sending access token to firebase functions
    const login = httpsCallable<unknown, LoginToken>(functions, 'login');
    login({ accessToken }).then(result => {
      signInWithCustomToken(auth, result.data.token).then(() => {
        console.log('Auth logged in successfully.');
      });
    }).catch(err => {
      console.error('login error:', JSON.stringify(err, null, ' '));
    });
  }
});
