import liff from '@line/liff';
import { Confirm, Divider, List, Statistic, Image, Header, Label, Icon } from "semantic-ui-react";
import { initialIsOpenedValue } from "./GroupEditor";
import { currentDateStore, currentGroupOwnerStore, currentMembersStore, currentPriceListStore, currentTagsStore, currentUserStore, snackbarStore } from '../services/store';
import { useObservable } from "react-use";
import { useEffect, useState } from "react";
import { User } from "../services/types";
import userSvg from '../assets/user.svg';

const MonthlyReport = ({isOpened, setIsOpened}: {isOpened: boolean, setIsOpened: React.Dispatch<React.SetStateAction<typeof initialIsOpenedValue>>}) => {

  const currentUser = useObservable(currentUserStore.valueChanges);
  const currentOwner = useObservable(currentGroupOwnerStore.valueChanges);
  const currentMenmbers = useObservable(currentMembersStore.valueChanges);
  const currentDate = useObservable(currentDateStore.valueChanges);
  const priceList = useObservable(currentPriceListStore.valueChanges);
  const tags = useObservable(currentTagsStore.valueChanges);

  const [memberPriceTotal, setMemberPriceTotal] = useState<{user: User, total: number}[]>([]);
  const [paymentDetail, setPaymentDetail] = useState<{user: User, expense: number}[]>([]);
  const [amIPayer, setAmIPayer] = useState(false);

  const onClose = () => {
    setIsOpened(initialIsOpenedValue);
  };
  const onConfirm = () => {
    if (!currentDate) return;
    if (!currentUser) return;

    if (!liff.isLoggedIn()) {
      liff.ready.then(() => {
        liff.login();
      });
    }

    let settledExpense: number;

    if (paymentDetail.filter(p => p.user.id !== currentUser.id).length) {
      settledExpense = paymentDetail.filter(p => p.user.id !== currentUser.id)[0].expense;
      settledExpense = Math.ceil(Math.abs(settledExpense));
    } else {
      return;
    }

    if (liff.isLoggedIn() && liff.isApiAvailable('shareTargetPicker')) {
      liff.shareTargetPicker([
          {
            type: 'text',
            text: `【わりかん家計簿 - Shared Expenses】\n${currentDate.year()}年${currentDate.month() + 1}月の精算金額は、¥${settledExpense.toLocaleString()}でした。`,
          },
        ]).then((res) => {
        if (res) {
          snackbarStore.update(prev => ({
            ...prev,
            isOpen: true,
            children: <Label size="large" color="green">{`[${res.status}] Message sent!`}</Label>,
          }));
        }
      }).catch(() => {
        snackbarStore.update(prev => ({
          ...prev,
          isOpen: true,
          children: <Label size="large" color="red">{'[Eroor] Something wrong happen'}</Label>,
        }));
      });
    }
  };

  useEffect(() => {
    if (!priceList) return;
    if (!currentOwner) return;
    setMemberPriceTotal(() => [
      {
        user: currentOwner,
        total: priceList.filter(price => price.userId === currentOwner.id).reduce((acc, cur) => acc + cur.price, 0),
      },
    ]);
    if (!currentMenmbers) return;
    setMemberPriceTotal(prev => [
      ...prev,
      ...currentMenmbers.map(member => ({
        user: member,
        total: priceList.filter(price => price.userId === member.id).reduce((acc, cur) => acc + cur.price, 0),
      })),
    ]);
  }, [currentMenmbers, currentOwner, priceList]);

  useEffect(() => {
    if (!currentUser) return;
    if (!memberPriceTotal.length) return;
    const allTotalSplit = memberPriceTotal.reduce((acc, cur) => acc + cur.total, 0) / memberPriceTotal.length;
    setPaymentDetail(() => memberPriceTotal.map(member => {
      const result = {
        user: member.user,
        expense: member.total - allTotalSplit,
      };
      if (member.user.id === currentUser.id && result.expense < 0) {
        setAmIPayer(true);
      } else {
        setAmIPayer(false);
      }
      return result;
    }));
  }, [currentUser, memberPriceTotal]);

  return (
    <Confirm
      open={isOpened}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      cancelButton={'Close'}
      confirmButton={!amIPayer ? {
        content: liff.isLoggedIn() ? 'Send Monthly Result' : 'Log in with LINE',
        icon: liff.isLoggedIn() ? 'comment' : 'sign-in',
        positive: !liff.isLoggedIn(),
        disabled: !paymentDetail.filter(p => p.user.id !== currentUser?.id).length,
      } : <></>}
      content={(
        <div className="content">
          <Divider fitted horizontal>
            <Statistic size="small">
              <Statistic.Label>{currentDate && currentDate.year()}</Statistic.Label>
              <Statistic.Value>{currentDate && currentDate.month() + 1}</Statistic.Value>
            </Statistic>
          </Divider>
          <div className="center mt2 mb2">
            <Statistic>
              {currentUser &&
                <>
                  <Statistic.Label style={{marginBottom: '10px'}}>
                    <Label image>
                      <Image avatar src={currentUser.pictureUrl ? currentUser.pictureUrl : userSvg} />
                      {currentUser.displayName}
                    </Label>
                    {paymentDetail.filter(p => p.user.id !== currentUser.id).map(p => (
                      <span key={p.user.id}>
                        {
                          p.expense < 0 ?
                            <Icon name="long arrow alternate left" size="large" style={{margin: '0 10px'}} /> :
                            <Icon name="long arrow alternate right" size="large" style={{margin: '0 10px'}} />
                        }
                        <Label image>
                          <Image avatar src={p.user.pictureUrl ? p.user.pictureUrl : userSvg} />
                          {p.user.displayName}
                        </Label>
                      </span>
                    ))}
                  </Statistic.Label>
                  <Statistic.Value>
                    {
                      !!paymentDetail.filter(p => p.user.id !== currentUser.id).length ?
                        paymentDetail.filter(p => p.user.id !== currentUser.id).map(p =>
                          <span key={p.user.id}>
                            ¥{Math.ceil(Math.abs(p.expense)).toLocaleString()}
                          </span>
                        ) :
                        '-'
                    }
                  </Statistic.Value>
                </>
              }
            </Statistic>
          </div>
          <div className="center">
            {(tags && priceList) && tags.map(tag => {
              const tagTotal = priceList.filter(price => price.tagIds.filter(tagId => tagId === tag.id).length > 0).reduce((acc, cur) => acc + cur.price, 0);
              return (
                <Label key={tag.id} color={tag?.color} style={{marginBottom: '.28571428em'}}>
                  <Icon name={tag?.icon ? tag.icon : 'hashtag'} />
                  {tag.label}
                  <Label.Detail>¥{tagTotal.toLocaleString()}</Label.Detail>
                </Label>
              );
            })}
          </div>
          <Divider hidden />
          <div className="center">
            <Header style={{marginBottom: 0}}>Total: ¥{memberPriceTotal.reduce((acc, cur) => acc + cur.total, 0).toLocaleString()}</Header>
            <List relaxed size="large" horizontal className="left" style={{marginTop: '0.5rem'}}>
              {memberPriceTotal.map(({user, total}) => (
                <List.Item key={user.id}>
                  <Image avatar src={user.pictureUrl ? user.pictureUrl : userSvg} />
                  <List.Content>
                    <small className="size1">{user.displayName}</small>
                    <List.Header>¥{total.toLocaleString()}</List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        </div>
    )} />
  );
};

export default MonthlyReport;
